<template>
  <div class="signup employer">
    <div class="signup-body">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="text-center col-12 pt-4 pt-lg-0">
            <h5 class="mb-0 mb-sm-4 signup-header">Employer Signup</h5>
          </div>
          <div class="steps col-md-10 col-lg-7 p-0 mb-4">
            <div class="d-none d-sm-block">
              <el-steps :active="step - 1" align-center>
                <el-step title="Step 1" description="Name and email" @click.native="goTo(0)" />
                <el-step title="Step 2" description="Company information" @click.native="goTo(1)" />
                <el-step title="Step 3" description="Payment" @click.native="goTo(2)" />
                <!-- <el-step :title="this.$route.query.plan=== 'basic' ? 'Step 3' :'Step 4'" description="Invite colleagues" @click.native="goTo(3)"></el-step>-->
              </el-steps>
            </div>
          </div>

          <div class="content col-md-10 col-lg-7 p-0">
            <div v-show="step == 1">
              <div class="header">
                <h3>Name and email</h3>
              </div>
              <hr />
              <name-and-email @next="next" />
            </div>
            <div v-if="step == 2">
              <div class="header">
                <h3>Company information</h3>
              </div>
              <hr />
              <company-info @back="back" @next="submitCompanyInfo" />
            </div>
            <div v-if="step == 3">
              <div class="header">
                <h3>Payment</h3>
              </div>
              <hr />
              <payment-form :signup="signup" :company-address="companyAddress" :company_type="company_type" create-next="employerCreateNext" :step="step" :freeSubscriptions="freeSubscriptions" />
            </div>
            <!--                        <div v-show="step == 3">-->
            <!--                            <div class="header">-->
            <!--                                <h3>Invite colleagues</h3>-->
            <!--                            </div>-->
            <!--                            <hr>-->
            <!--                            <div class="form">-->
            <!--                                <p>Send an invitation to your colleagues to join your team.</p>-->
            <!--                                <el-form ref="form" :model="signup">-->
            <!--                                    <el-row v-for="(c, index) in colleagues" :key="index" :gutter="20" class="mb-3">-->
            <!--                                        <el-col :span="8">-->
            <!--                                            <el-form-item label="First name" prop="invite_first_name">-->
            <!--                                                <el-input v-model="c.first_name"-->
            <!--                                                          @onblur="invitation.push(c.first_name)"></el-input>-->
            <!--                                            </el-form-item>-->
            <!--                                        </el-col>-->
            <!--                                        <el-col :span="8">-->
            <!--                                            <el-form-item label="Last name" prop="invite_last_name">-->
            <!--                                                <el-input v-model="c.last_name"></el-input>-->
            <!--                                            </el-form-item>-->
            <!--                                        </el-col>-->
            <!--                                        <el-col :span="8">-->
            <!--                                            <el-form-item label="Email" prop="invite_email">-->
            <!--                                                <el-input v-model="c.email"></el-input>-->
            <!--                                            </el-form-item>-->
            <!--                                        </el-col>-->
            <!--                                    </el-row>-->
            <!--                                    <div class=" text-center mb-5">-->
            <!--                                        <a href="#" class="invite-another orange-text"-->
            <!--                                           @click.prevent="addNewColleagueRow"><i class='el-icon-circle-plus'></i>-->
            <!--                                            Invite another colleague</a>-->
            <!--                                    </div>-->
            <!--                                    <el-row type="flex" class="row-bg buttons" justify="space-between">-->
            <!--                                        <el-col :span="6">-->
            <!--                                            <el-form-item class="">-->
            <!--                                                <el-button type="info" @click="back">Back</el-button>-->
            <!--                                            </el-form-item>-->
            <!--                                        </el-col>-->
            <!--                                        <el-col :span="6">-->
            <!--                                            <el-form-item class="text-center">-->
            <!--                                                <el-button plain @click="next">Skip</el-button>-->
            <!--                                            </el-form-item>-->
            <!--                                        </el-col>-->
            <!--                                        <el-col :span="6">-->
            <!--                                            <el-form-item class="text-right">-->
            <!--                                                <el-button type="success"-->
            <!--                                                           @click="createEmployerInvite({email:signup.email, invitation: colleagues}),generateToken({username: signup.email, password: signup.password})">-->
            <!--                                                    Submit-->
            <!--                                                </el-button>-->
            <!--                                            </el-form-item>-->
            <!--                                        </el-col>-->
            <!--                                    </el-row>-->
            <!--                                </el-form>-->
            <!--                            </div>-->
            <!--                        </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../vuex/store';
import _ from 'lodash';
import {mapGetters, mapActions} from 'vuex';
import PaymentForm from '../../components/PaymentForm';
import NameAndEmail from '~/components/SignUp/Employer/NameAndEmail.vue';
import CompanyInfo from '~/components/SignUp/Employer/CompanyInfo.vue';

export default {
  store,

  components: {
    PaymentForm,
    NameAndEmail,
    CompanyInfo,
  },

  data() {
    return {
      // required by payment form
      company_type: 'employer',
      signup: {
        email: '',
        billing_information: {
          address_1: '',
          address_2: '',
          city: '',
          zip: '',
          country: '',
          state: '',
          company_type: 'employer',
        },
      },
      companyAddress: {},
      // inviteErr: null,
      // inviteResp: null,
      // colleagues: [{
      //   first_name: '',
      //   last_name: '',
      //   email: '',
      // }],
      step: 1,
    };
  },

  computed: mapGetters({
    freeSubscriptions: 'getFreeSubscriptionsCount',
  }),

  watch: {
    '$route.query.step': function(newValue) {
      this.step = newValue || 1;
    },
  },

  mounted() {
    this.$store.dispatch('getFreeSubscriptionCount');
  },

  methods: {
    ...mapActions({
      generateToken: 'authentication',
      create_next: 'employerCreateNext',
    }),
    
    routerPush() {
      if (this.step == 1) {
        this.$router.push({path: `/employer/signup`});
      } else {
        this.$router.push({path: `/employer/signup`, query: {step: this.step}});
      }
    },

    // createEmployerInvite (payload) {
    //   axios.post(constant.LOCAL_BASE_URL + constant.EMPLOYER_SIGNUP_INVITE, payload)
    //     .then(response => {
    // this.inviteErr = ''
    // ++this.step
    //           'show_welcome=true; expires=Thu, 01 Jan 2100 00:00:00 UTC; path=/;'
    // },
    next() {
      ++this.step;
      this.routerPush()
    },

    back() {
      --this.step
      this.routerPush()
      this.scrollToTop()
    },

    goTo(newStep) {
      // only allow going backwards if navigating by clicking tabs
      if (this.step > newStep) {
        this.step = newStep;
      }
    },

    submitCompanyInfo(companyInfo) {
      this.companyAddress = _.pick(companyInfo, ['address_1', 'address_2', 'state', 'city', 'country', 'zip']);

      // update billing info country if not yet set
      const billingInformation = this.signup.billing_information;
      if (!billingInformation.country) {
        billingInformation.country = companyInfo.country;
      }

      this.next();
    },

    // addNewColleagueRow () {
    //   this.colleagues.push({
    //     first_name: '',
    //     last_name: '',
    //     email: '',
    //   })
    // },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.el-input__inner:focus,
.el-textarea__inner:focus,
.el-radio.is-bordered.is-checked {
  border: 1px solid #e87722 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #e87722 !important;
  background-color: #e87722 !important;
}

.el-button.is-plain:focus,
.el-button.is-plain:hover,
.el-radio__input.is-checked + .el-radio__label {
  border-color: #e87722 !important;
  color: #e87722 !important;
}

.el-radio.is-bordered {
  margin-right: 10px;
}

.employer.signup {
  .password-strength {
    margin-left: 0;
  }
  .el-select .el-input__suffix {
    top: 40px;
    height: 40px;
  }

  .el-select {
    display: block;
  }

  .validation-enter-active,
  .validation-leave-active {
    transition: opacity 0.5s;
  }

  .validation-enter, .validation-leave-to /* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
  }
}
</style>

<template>
    <div class="form">
        <loading-page :active.sync="isLoading" />
        <el-form v-if="freeSubscriptions <= 500" ref="form"
                 :model="signup">
            <!-- if < 500 -->
            <img src="/images/payment-free.png"
                 width="605" height="230" alt="" class="payment-free mb-3">

            <el-row type="flex" class="row-bg buttons" justify="space-between">
                <el-col :span="6">
                    <el-form-item class="">
                        <el-button type="info" @click="back">Back</el-button>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item class="text-right">
                        <el-button type="success"
                                   @click="subscribe(true),
                                   isLoading=true"
                                   :diabled="isLoading">Next
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <!-- end < 500 -->
        <el-form v-else ref="form" :model="signup">
            
            <p class="orange-text bold mb-3 text-left"><i class="el-icon-bank-card"></i> Payment information</p>

            <el-row :gutter="20" id="payment-form">
                <el-col :xs="24" :md="24" class="toggle-btns">
                    <!-- <p class="text-center ribbon">Great news! We are offering <span class="bold">75% off</span> to the next 500 employers!</p> -->
                    <ul class="tab payment-tab p-0 text-center">
                        <li>
                            <input id="free"
                                   type="radio"
                                   v-model="subscription.subscription_type"
                                   value="free"
                                   name="payment_type"
                                   @click="clearBilling"
                                   required="">
                            <label for="free" class="free">
                                <!-- <span class="h2 small free-block">Free</span> -->
                                <span class="h2 bold">FREE
                                </span>
                                <br>
                                <span class="small bold">
                                    Trial
                                </span>
                            </label>
                        </li>
                        <li>
                            <input id="monthly"
                                   type="radio"
                                   v-model="subscription.subscription_type"
                                   value="monthly"
                                   name="payment_type"
                                   required="">
                            <label for="monthly" class="">
                                <!-- <span class="h2 strikethrough small">$69</span> <span class="h2 bold">$17.25</span> -->
                                <span class="h2 bold">$69</span>
                                <br>
                                <span class="small bold">Monthly</span></label>
                        </li>
                        <li>
                            <input id="yearly"
                                   type="radio"
                                   v-model="subscription.subscription_type"
                                   value="yearly"
                                   name="payment_type"
                                   required="">
                            <label for="yearly" style="" class="">
                                <!-- <span class="h2 strikethrough small">$690</span> <span class="h2 bold">$172.50</span> -->
                                <span class="h2 bold">$690</span>
                                <br>
                                <span class="small bold">Yearly</span>
                            </label>
                        </li>
                    </ul>
                </el-col>
            </el-row>
            <div v-show="subscription.subscription_type !==
             'free'">
                <el-row :gutter="20">
                    <el-col :xs="24">
                        <el-form-item label="Coupon" prop="coupon">
                            <el-input v-model="subscription.coupon" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!--            <el-row :gutter="20">-->
                <!--                <el-col :xs="12" :md="12">-->
                <!--                    <div class="mb-4">-->
                <!--                        <el-radio-group v-model="subscription.subscription_type">-->
                <!--                            <el-radio v-model="subscription.subscription_type" label="monthly" border>Monthly</el-radio>-->
                <!--                            <el-radio v-model="subscription.subscription_type"  label="yearly"  border>Yearly</el-radio>-->
                <!--                        </el-radio-group>-->
                <!--                    </div>-->
                <!--                </el-col>-->
                <!--                <el-col :xs="24">-->
                <!--                    <div class="mb-4">-->
                <!--                        <el-form-item label="Coupon" prop="coupon">-->
                <!--                            <el-input v-model="coupon" ></el-input>-->
                <!--                        </el-form-item>-->
                <!--                    </div>-->
                <!--                </el-col>-->
                <!--            </el-row>-->
                <p class="orange-text bold text-left"><i class="el-icon-bank-card"></i> Card information</p>
                <!--            <pre>-->
                <!--                {{ signup.billing_information }}-->
                <!--            </pre>-->
                <el-row :gutter="20">
                    <el-col :xs="24">
                        <el-form-item label="Name on Card"
                                      prop="card_name" :rules="[{ required: true, message: ' '}]">
                            <el-input
                                    v-model="cardHolderName"
                                    autocomplete="on"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item
                                label="Credit Card Number" prop="card_number" :rules="[{ required: true, message: ' '}]">
                            <div class="card-input" id="card-number"></div>
                            <div class="form-error" v-show="invalidCard">
                                Invalid card.
                            </div>
                            <div id="card-image" :class="cardType"></div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :xs="24" :md="8">
                        <el-form-item label="Expiry Date"
                                      class="card-info" :rules="[{ required: true, message: ' '}]">
                            <div class="card-input" id="expiration-date"></div>
                            <div class="form-error" v-show="invalidExpiration">
                                Invalid expiration date.
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="8">
                        <el-form-item label="CVV" class="card-info" :rules="[{ required: true, message: ' '}]">
                            <div class="card-input" id="cvv"></div>
                            <div class="form-error" v-show="invalidCvv">
                                Invalid CVV.
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="8">
                        <el-form-item label="Zip Code"
                                      prop="postal_code" :rules="[{ required: true, message: ' '}]">
                            <div class="card-input" id="postal-code"></div>
                            <div class="form-error" v-show="invalidPostal">
                                Invalid Zip.
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <p class="mt-4 mb-4 orange-text bold text-left"><i class="el-icon-office-building"></i> Billing Address</p>
                <el-row :gutter="20">
                    <el-col :xs="24">
                        <el-checkbox class="mb-2" v-model="sameAsCompanyAddress">Same as Company Address</el-checkbox>
                        <el-form-item label="Address 1"
                                      prop="street_address" :rules="[{ required: true, message: ' '}]">
                            <el-input v-model="signup.billing_information.address_1" @blur="!companyBillingResp? '' : createOrUpdateEmployerBillingInfo({address_1: signup.billing_information.address_1})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="Address 2" prop="extended_address">
                            <el-input v-model="signup.billing_information.address_2" @blur="!companyBillingResp? '' : createOrUpdateEmployerBillingInfo({address_1: signup.billing_information.address_2})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item label="State" prop="state">
                            <el-input v-model="signup.billing_information.state" @blur="!companyBillingResp? '' : createOrUpdateEmployerBillingInfo({address_1: signup.billing_information.state})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item label="City"
                                      prop="city">
                            <el-input
                                    v-model="signup.billing_information.city" @blur="!companyBillingResp? '' : createOrUpdateEmployerBillingInfo({city: signup.billing_information.city})"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="Country" prop="country" :rules="[{ required: true, message: ' '}]">
                            <country-select v-model="signup.billing_information.country" @input="!companyBillingResp? '' : createOrUpdateEmployerBillingInfo({address_1: signup.billing_information.country})" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-alert v-if="companyBillingErr"
                          type="error"
                          title="The following is required:"
                          :closable="click=false"
                          center="center"
                          @close="true"
                          class="signup-alert"
                          id="billing_info_error">
                    <ul>
                        <li v-for="ccInfo in
                        companyBillingErr['billing_information.cc_info']" :key="ccInfo">
                            <i class="el-icon-error"></i>
                            {{ ccInfo }}
                        </li>
                        <li v-for="ccName in companyBillingErr['billing_information.cc_name']" :key="ccName">
                            <i class="el-icon-error"></i> {{ ccName }}
                        </li>
                        <li v-for="ccExpiry in companyBillingErr['billing_information.cc_expiry_date']" :key="ccExpiry">
                            <i class="el-icon-error"></i> {{ ccExpiry }}
                        </li>
                        <li v-for="ccCvv in companyBillingErr['billing_information.cc_cvv']" :key="ccCvv">
                            <i class="el-icon-error"></i> {{ ccCvv }}
                        </li>
                        <li v-for="addr1 in companyBillingErr['billing_information.address_1']" :key="addr1">
                            <i class="el-icon-error"></i> {{ addr1 }}
                        </li>
                        <li v-for="addr2 in companyBillingErr['billing_information.address_2']" :key="addr2">
                            <i class="el-icon-error"></i> {{ addr2 }}
                        </li>
                        <li v-for="zip in companyBillingErr['billing_information.zip']" :key="zip">
                            <i class="el-icon-error"></i> {{ zip }}
                        </li>
                        <li v-for="state in companyBillingErr['billing_information.state']" :key="state">
                            <i class="el-icon-error"></i> {{ state }}
                        </li>
                        <li v-for="city in
                        companyBillingErr['billing_information.city']" :key="city">
                            <i class="el-icon-error"></i>
                            {{ city }}
                        </li>
                        <li v-for="title in companyBillingErr['billing_information.title']" :key="title">
                            <i class="el-icon-error"></i> {{ title }}
                        </li>
                        <li v-for="country in companyBillingErr['billing_information.country']" :key="country">
                            <i class="el-icon-error"></i> {{ country }}
                        </li>
                    </ul>
                </el-alert>
                <el-alert v-if="braintreeError"
                          type="error"
                          :closable="click=false"
                          center="center"
                          @close="true"
                          class="signup-alert"
                          id="braintree_info_error">
                    <ul>
                        <li>
                            <i class="el-icon-error"></i>
                            {{ braintreeError }}
                        </li>
                    </ul>
                </el-alert>
            </div>
            <el-row type="flex" class="row-bg buttons" justify="space-between">
                <el-col :span="6">
                    <el-form-item class="">
                        <el-button type="info" @click="back">Back</el-button>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item class="text-right">
                        <el-button type="success"
                                   @click="subscribe(false)"
                                   :diabled="isLoading">Next
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
   </div>
</template>
<script>
import CountrySelect from '~/components/CountrySelect.vue'
import axios from "../client";
import {client, hostedFields} from "braintree-web";
import {mapActions} from "vuex";
import {LOCAL_BASE_URL, SUBSCRIBE, BILLING_TOKEN} from '../vuex/utils/constant'
import LoadingPage from "./LoadingPage";
import * as constant from "../vuex/utils/constant";
import loginFromOnboarding from '~/helpers/loginFromOnboarding'
import PostAffiliatePro from '~/services/PostAffiliatePro'
import Employer from '~/models/Employer'

export default {
    name: 'PaymentForm',
    created() {
        this.init();
    },
    components: {
        LoadingPage,
        CountrySelect,
    },
    props: {
        signup: {
            type: Object,
            required: true,
        },
        companyAddress: {
            type: Object,
            required: true,
        },
        company_type: {
            type: String,
            required: true
        },
        createNext: {
            type: String,
            required: true
        },
        step: {
            type: [ Number, String ],
            required: false
        },
        freeSubscriptions: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            sameAsCompanyAddress: false,
            isLoading: false,
            subscription: {
                token: null,
                subscription_type: 'monthly',
                coupon: null,
                plan_type: 'Silver' //Todo: add plan model hardcode temporarily
            },
            braintreeError: null,
            companyBillingErr:null,
            companyBillingResp:null,
            isSubscribed: false,
            cardName: '',
            vaultedPaymentMethods: null,
            hostedFieldsInstance: null,
            tokenizePayload: null,
            card: '',
            invalidCard: false,
            invalidCvv: false,
            invalidExpiration: false,
            invalidPostal: false,
            cardIsValid: false,
            cardHolderName: null,
            cVv: null,
            // we save the billing address here before user checked the sameAsCompanyAddress
            // checkbox, so that we can revert it if user changes his/her mind
            originalBillingAddress: {},
        }
    },
    computed: {
        cardType() {
            return this.card
        },
        // create_next() {
        //     return 'freelancerCompanyCreateNext'
        // }
    },
    watch: {
        sameAsCompanyAddress (newValue) {
            if (newValue) {
                // right before overwriting the billing address, we save the original values
                this.originalBillingAddress = { ...this.signup.billing_information }
                this.signup.billing_information = { ...this.companyAddress }
            } else {
                // revert to original values
                this.signup.billing_information = { ...this.originalBillingAddress }
            }
        },

        companyAddress: {
           handler (newValue) {
               if (this.sameAsCompanyAddress) {
                   this.signup.billing_information = { ...newValue }
               }
           },
           deep: true,
        },
    },
    methods: {
        ...mapActions({
            create_next(dispatch, payload) {
                return dispatch(this.createNext, payload)
            },
            generateToken: 'authentication',
        }),
        back() {
            this.scrollToTop();
            --this.$parent.step;
        },
        init () {
            return axios.get(LOCAL_BASE_URL + BILLING_TOKEN)
                .then(response => {
                    client.create({
                        authorization: response.data.token
                    }, (clientErr, clientInstance) => {
                        if (clientErr) {
                            // this.errorMessage = `There was an error setting up the client instance. Message: ${clientErr.message}`;
                            this.$message.error({
                                message: clientErr.message,
                                center: true,
                                showClose: true
                            });
                            this.$emit('braintreeError', this.errorMessage);
                            return;
                        } else {
                            this.clientInstance = clientInstance
                            this.createField();
                        }
                    });
                })
        },
        createField: function () {
            hostedFields.create({
                client: this.clientInstance,
                styles: {
                    'input, select': {
                        'border-radius': '4px',
                        'font-size': '14px',
                        'height': '40px',
                        'color': '#606266'
                    },
                    ':focus': {
                        'color': '#606266',
                    },
                    '.invalid': {
                        'color': '#EB5757'
                    }

                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: '•••• •••• •••• ••••',
                    },
                    cvv: {
                        selector: '#cvv',
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: 'MM/YY'
                    },
                    postalCode: {
                        selector: '#postal-code',
                        prefill: this.companyAddress.zip
                    }
                }
            }, (fieldsError, hostedFieldsInstance) => {
                console.log(fieldsError);
                hostedFieldsInstance.on('cardTypeChange', (event) => {
                    try {
                        this.card = event.cards[0].type;
                        // this.cardName = event.cards[0].niceType;
                    } catch (e) {
                        this.card = '';
                        // this.cardName = '';
                    }

                });
                hostedFieldsInstance.on('blur', (event) => {
                    let field = event.fields[event.emittedBy];
                    switch (event.emittedBy) {
                        case "number":
                            this.invalidCard = !field.isValid;
                            break;
                        case "cvv":
                            this.invalidCvv = !field.isValid;
                            break;
                        case "expirationDate":
                            this.invalidExpiration = !field.isValid;
                            break;
                        case "postalCode":
                            this.invalidPostal = !field.isValid;
                    }
                });
                hostedFieldsInstance.on('empty', (event) => {
                    let field = event.fields[event.emittedBy];
                    if(field.isEmpty) {
                        this.validCard = false;
                        this.card = ' '
                    }
                });
                hostedFieldsInstance.on('validityChange', (event)  => {
                    let field = event.fields[event.emittedBy];
                    if (! field.isValid) {
                        this.validCard = true
                    } else if (! field.isPotentiallyValid) {
                        this.validCard = true
                    } else {
                        this.validCard = false
                    }
                });
                if (fieldsError) {
                    // Handle error in Hosted Fields creation
                    this.$message.error({
                        message: fieldsError.message,
                        center: true,
                        showClose: true
                    });
                    // this.errorMessage = 'There was an error setting up the hosted fields! Message: ' + fieldsError.message;
                    this.$emit('braintreeError', this.errorMessage);
                    return;
                } else {
                    this.hostedFieldsInstance = hostedFieldsInstance;
                }

            });
        },

        loginFromOnboarding () {
            Employer.find('my-profile').then(response => {
                loginFromOnboarding(response.data, 'employer')
            })
        },

        disable() {
            this.hostedFieldsInstance.setAttribute({
                field: 'number',
                attribute: 'disabled',
            });
            this.hostedFieldsInstance.setAttribute({
                field: 'cvv',
                attribute: 'disabled',
            });
            this.hostedFieldsInstance.setAttribute({
                field: 'expirationDate',
                attribute: 'disabled',
            });
            this.hostedFieldsInstance.setAttribute({
                field: 'postalCode',
                attribute: 'disabled',
            });
        },
        getState() {
            let state = this.hostedFieldsInstance.getState();

            let valid = Object.keys(state.fields).every(function (key) {
                return state.fields[key].isValid;
            });
            if(valid) {
                this.cardIsValid = true
            }
        },
        tokenize () {
            //fixes token when accessing from data
            return new Promise((resolve) => {
                this.hostedFieldsInstance.tokenize({
                    cardholderName: this.cardholderName
                } , (tokenizeErr, payload) => {
                    if (tokenizeErr) {
                        this.invalidCard = true;
                        this.invalidCvv = true;
                        this.invalidExpiration = true;
                        this.invalidPostal = true;
                        this.companyBillingErr = {
                            'billing_information.cc_info'
                                : [
                                tokenizeErr.message
                            ]
                        };
                        // this.$message.error({
                        //     message: tokenizeErr.message,
                        //     center: true,
                        //     showClose: true
                        // });
                        // this.errorMessage = 'There was an error tokenizing! Message: ' + tokenizeErr.message;
                        this.$emit('braintreeError', this.errorMessage);
                        this.isLoading = false;
                        return;
                    }
                    this.getState();
                    if(this.cardIsValid) {
                        // console.log('payload loaded')
                        this.tokenizePayload = payload;
                        this.subscription.token = payload.nonce
                        //emmit from parent employer
                        this.$emit('tokenLoaded', [{
                            token: payload
                        }]);

                    }
                    resolve(payload)
                })
            })

        },
        teardownHF () {
            this.hostedFieldsInstance.teardown( (teardownErr) => {
                if (teardownErr) {
                    this.errorMessage = 'There was an error tearing it down! Message: ' + teardownErr.message;
                    this.$emit('braintreeError', this.errorMessage);
                    return;
                } else {
                    this.hostedFieldsInstance = '';
                    return;
                }
            });
        },
        async subscribe(promo = false) {
            this.companyBillingErr = this.braintreeError = null;
            this.isLoading = true;
            if(this.isSubscribed) {
                ++this.$parent.step
                this.$parent.enableStep4 = true;
            } else if(this.subscription.subscription_type
                === 'free') {
                // login from onboarding
                Employer.find('my')
                    .then(response => loginFromOnboarding(response.data, 'employer'))
            } else {
                // Change plan type to Promo if promo is active
                if (promo) {
                    this.subscription.plan_type = 'Promo';
                } else {
                    await this.tokenize();
                }

                // this.createOrUpdateEmployerBillingInfo(true)
                // console.log(this.tokenizePayload)
                axios.post(LOCAL_BASE_URL + SUBSCRIBE, {
                    ...this.signup, ...this.subscription, company_type: this.company_type
                }, { headers: Employer.headers() }).then(response => {
                    this.isLoading = false;
                    this.isSubscribed = true;

                    if(response.data.subscription &&
                        response.data.subscription.braintree_id
                        !== '') {
                        this.disable();
                        // track affiliate sale
                        PostAffiliatePro.trackSubscription(response.data.subscription)
                    }

                    // login from onboarding
                    // add 200 ms delay to ensure affiliate request is completed
                    setTimeout(() => loginFromOnboarding(response.data, 'employer'), 200);
                }).catch((error) => {
                    let errorMsg;
                    this.isLoading = false;
                    console.log(error);
                    if ("exception" in
                        error.response.data) {
                        errorMsg =
                            error.response.data.message.replace('SILVERMONTHLY', '');
                        errorMsg =
                            errorMsg.replace('SILVERYEARLY', '');
                        this.braintreeError =
                            errorMsg;
                    } else {
                        this.companyBillingErr =
                            error.response.data.errors;
                    }
                    // this.$message.error({
                    //     message: error.response.data.message,
                    //     center: true,
                    //     showClose: true
                    // });
                })
            }
        },
        createOrUpdateEmployerBillingInfo(submit = false) {
            axios.post(constant.LOCAL_BASE_URL + constant.EMPLOYER_SIGNUP_BILLING_INFO, this.signup)
                .then(response => {
                    this.companyBillingResp = response.data;
                    this.status = false
                    // this.isLoading = false;
                })
                .catch(error => {
                    if(submit === true) {
                        this.companyBillingErr = error.response.data.errors;
                    }
                    this.status = false
                    // this.isLoading = false;
                    return error;
                });
        },

        clearBilling() {
            this.signup.billing_information = {
                address_1 : "",
                address_2 : "",
                company_type : "employer",
                country : "",
                state : "",
                zip: ""
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>
<style scoped lang="scss">
    .ribbon {
        background: #E87722;
        padding: 8px 15px;
        color: white;
        border-radius: 5px;
        word-break: break-word;
    }
    @media (max-width: 1199px) {
        .payment-free {
            width: 100%;
            height: auto;
        }
    }
    
    .braintree-hosted-fields-focused {
        transition: all 200ms ease;
        border-color: #E87722 !important;
    }
    #card-image {
        position: absolute;
        top: 46px;
        right: 1em;
        width: 44px;
        height: 28px;
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
        background-size: 86px 458px;
        border-radius: 4px;
        background-position: -100px 0;
        background-repeat: no-repeat;
        margin-bottom: 1em;
        &.visa {
            background-position: 0 -398px;
        }
        &.master-card {
            background-position: 0 -281px;
        }
        &.american-express {
            background-position: 0 -370px;
        }
        &.discover {
            background-position: 0 -163px;
        }
        &.maestro {
            background-position: 0 -251px;
        }
        &.jcb {
            background-position: 0 -221px;
        }
        &.diners-club {
            background-position: 0 -133px;
        }
    }
    .form-error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 90%;
        left: 0;
    }
    .card-input {
        -webkit-appearance: none;
        background-color: #FFF;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
    }
    #expiration-month {
        border-right: none;
    }

    .braintree-hosted-fields-invalid {
        border-color: #EB5757;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #E87722 !important;
        background-color: #E87722 !important;
    }
    .el-button.is-plain:focus, .el-button.is-plain:hover, .el-radio__input.is-checked+.el-radio__label{
        border-color: #E87722 !important;
        color: #E87722 !important;
    }
</style>

<template>
  <div class="form">
    <el-form ref="form">
      <el-row :gutter="20">
        <el-col :xs="24" :md="12" class="step1-required">
          <el-form-item label="First Name" prop="first_name">
            <el-input v-model="signup.first_name" @blur="saveIfUpdate" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12" class="step1-required">
          <el-form-item label="Last Name" prop="last_name">
            <el-input v-model="signup.last_name" @blur="saveIfUpdate" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12" class="step1-required">
          <el-form-item label="Email" prop="email">
            <el-input v-model="signup.email" @blur="saveIfUpdate" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12" class="step1-required">
          <p class="country-is-required">Country</p>
          <el-form-item prop="country">
            <country-select
              v-model="signup.company.country"
              @input="saveIfUpdate"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :md="12" class="step1-required">
          <el-form-item label="Password" prop="password">
            <el-input v-model="signup.password" type="password" @blur="saveIfUpdate" />
          </el-form-item>
          <password-requirements :password="signup.password" />
        </el-col>
        <el-col :xs="24" :md="12" class="step1-required">
          <el-form-item label="Confirm Password" prop="password_confirmation">
            <el-input v-model="signup.password_confirmation" type="password" @blur="saveIfUpdate" />
          </el-form-item>
        </el-col>
        <!--                                        <el-col :xs="24">-->
        <!--                                            <el-form-item label="Where did you hear about us?"-->
        <!--                                                          prop="where_did_you_hear">-->
        <!--                                                <el-input @blur="regResp ? updateNameEmail(): ''" v-model="signup.about_us"></el-input>-->
        <!--                                            </el-form-item>-->
        <!--                                        </el-col>-->
        <el-col :xs="24">
          <el-form-item>
            <agreement-checkbox v-model="signup.agreement" />
          </el-form-item>
        </el-col>
      </el-row>
      <error-alert :errors="SignUp.errors" class="signup-alert" allow-html />
      <el-form-item class="text-right buttons">
        <el-button type="success" :disabled="proceeding" :loading="proceeding" @click="next">
          Next
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import CountrySelect from '~/components/CountrySelect.vue'
import ErrorAlert from '~/components/ErrorAlert.vue'
import EmployerSignUp from '~/models/SignUp/Employer'
import isOnboarding from '~/helpers/isOnboarding'
import AgreementCheckbox from '~/components/SignUp/AgreementCheckbox.vue'
import PasswordRequirements from '~/components/SignUp/PasswordRequirements.vue'
import savesFirstRegistrationPage from '~/mixins/savesFirstRegistrationPage'

export default {
  components: {
    CountrySelect,
    ErrorAlert,
    AgreementCheckbox,
    PasswordRequirements,
  },

  mixins: [savesFirstRegistrationPage],

  data () {
    return {
      SignUp: new EmployerSignUp(isOnboarding('employer') ? 'my' : ''),
      type: 'employer',
    }
  },

  watch: {
    'signup.country' (newValue) {
      this.$emit('select-country', newValue)
    },
  },
}
</script>

<template>
  <div class="form">
    <el-form ref="form">
      <el-row :gutter="20">
        <el-col :xs="24" class="">
          <el-form-item prop="company_name" :rules="[{ required: true, message: ' '}]">
            <p class="mb-1 is-required">Company Name</p>
            <p class="small">This can be your personal name if you are an entrepreneur</p>
            <el-input v-model="company.company_name" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" class="mb-3">
          <el-form-item label="Company URL" prop="company_url">
            <el-input v-model="company.company_url" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" class="employer-uploader">
          <p>Please upload your company logo</p>
          <logo-uploader :company="company" class="company-profile"/>
        </el-col>
        <el-col :xs="24" class="">
          <el-form-item label="Tell us something about your company">
            <el-input
              v-model="company.description"
              type="textarea"
              :rows="3"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24">
          <p class="mt-4 orange-text bold">
            <i class="el-icon-office-building" />
            Company address
          </p>
          <el-form-item
            label="Address 1"
            prop="street_address"
            :rules="[{ required: true, message: ' '}]"
          >
            <el-input v-model="company.address_1" />
          </el-form-item>
        </el-col>
        <el-col :xs="24">
          <el-form-item label="Address 2" prop="extended_address">
            <el-input v-model="company.address_2" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <p class="country-is-required">Country</p>
          <el-form-item prop="country" :rules="[{ required: true, message: ' '}]">
            <country-select v-model="company.country" />
            <!-- <el-select
              v-model="company.country"
              @blur="saveIfRequiredAreComplete"
              filterable
              placeholder="Select"
            >
              <el-option
                  v-for="item in countries"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item v-if="company.country === 'US'" label="State" prop="state">
            <el-select
              v-model="company.state"
              filterable
              placeholder="Select"
              class="w-100"
            >
              <el-option
                v-for="item in states"
                :key="item.abbreviation"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="State" prop="state">
            <el-input v-model="company.state" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item label="Zip Code" prop="zip_code" :rules="[{ required: true, message: ' '}]">
            <el-input v-model="company.zip" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item label="City" prop="city">
            <el-input v-model="company.city" />
          </el-form-item>
        </el-col>
      </el-row>
      <error-alert :errors="Company.errors" class="signup-alert" />
      <el-row type="flex" class="row-bg buttons" justify="space-between">
        <el-col :span="6">
          <el-form-item class="">
            <el-button type="info" @click="back">Back</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item class="text-right">
            <el-button type="success" :loading="proceeding" @click="next">Next</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import CountrySelect from '~/components/CountrySelect'
import Employer from '~/models/Employer'
import ErrorAlert from '~/components/ErrorAlert.vue'
import states from '~/data/us_states'
import LogoUploader from '~/components/Employer/LogoUploader'

export default {
  components: { CountrySelect, ErrorAlert, LogoUploader },

  data () {
    return {
      Company: new Employer('my-company'),
      proceeding: false,
      states,
    }
  },

  computed: {
    company () {
      return this.Company.data
    },
  },

  watch: {
    'company.company_id' (newValue) {
      if (newValue) {
        // add onboarding flag so backend knows how to validate description field
        this.company.onboarding = true
      }
    },
  },

  methods: {
    back () {
      this.$emit('back')
    },

    next () {
      this.proceeding = true
      // save, and then move to the next tab
      this.save().then(() => {
        this.proceeding = false
        this.$emit('next', this.company)
      }).catch(() => {
        this.proceeding = false
      })
    },

    save () {
      return this.Company.save({ successMessage: false })
    },

    saveIfRequiredAreComplete () {
      const company = this.company
      if (company.company_name && company.address_1) {
        this.save()
      }
    },
  },
}
</script>
